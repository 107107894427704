<template>
  <div>
    <BreadCrumb :items="breadCrumb" />

    <EditTable class="category_list" primary-key="id" :items="category.books" :fields="fields" :sortFields="sortFields" :showControl="false"
      :busy="loading" selectable outlined striped hover sort-icon-left
      @row-selected="onRowSelected"
    >
      <template v-slot:title>
        <h5>{{ category.title }}</h5>
      </template>
      <template v-slot:cell(isbn)="cell">
        <span>{{ cell.item.isbn_13 }}</span><br />
        <span>{{ cell.item.isbn_10 }}</span>
      </template>
      <template v-slot:cell(author)="cell">
        {{ cell.value.join(', ') }}
      </template>
      <template v-slot:cell(publish)="cell">
        {{ cell.item.publisher.join(', ') }}<br />
        <YearIcon /> {{ cell.item.published_year }}
      </template>
      <template v-slot:cell(coverimage)="cell">
        <div class="img_div">
          <b-img :src="cell.value[0]" :alt="cell.item.title" :title="cell.item.title" />
        </div>
      </template>
    </EditTable>

  </div>
</template>

<script>
import YearIcon from 'mdi-vue/CalendarText';
import { mapState } from 'vuex';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditTable from '@/components/ui/EditTable.vue';

export default {
  name: 'BookCategory',
  components: { YearIcon, BreadCrumb, EditTable },

  data() {
    return {
      cat_id: this.$route.params.cat_id,
      loading: false,
      fields: [
        { key: 'coverimage', label: 'Cover' },
        { key: 'title', sortable: true },
        { key: 'author', sortable: true },
        { key: 'publish', label: 'Publisher' },
        { key: 'isbn' },
      ],
      sortFields: [
        { key: 'isbn_13', label: 'ISBN 13' },
        { key: 'isbn_10', label: 'ISBN 10' },
        { key: 'publisher', label: 'Publisher' },
        { key: 'published_year', label: 'Pub. Year' },
      ],
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books Categories', to: { name: 'book-cat-list' } },
        { text: 'Category', active: true },
      ];
    },
    category() {
      const filtered = this.categories.filter(c => c.id === this.cat_id);
      return filtered.length ? filtered[0] : {};
    },
    ...mapState('book', {
      categories: state => state.categories,
    }),
  },

  methods: {
    getCategories() {
      this.loading = true;
      this.$store.dispatch('book/getAllCategories').catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
    onRowSelected(item) {
      if (item.length > 0) this.$router.push({ name: 'book-preview', params: { book_id: item[0].id } });
    },
  },

  created() {
    if (!this.categories || (this.categories.length === 0)) this.getCategories();
  },

};
</script>

<style lang="stylus" scoped>

.category_list
  .img_div
    position relative
    width 3rem
    img
      width 100%

</style>
